import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";
    return token;
}

const attributes = {
    getManagementData: () => {        
        return HTTPAPI(grabToken())
        .get("/attributes/manage")
    },
    createCommonAttribute: (data) => {
        return HTTPAPI(grabToken())
        .post("/attributes/commonattribute", data);
    },
    createNetworkAttribute: (code, data) => {
        return HTTPAPI(grabToken())
        .post("/attributes/networkattribute/" + code, data);
    },
    createDeviceTypeAttribute: (code, data) => {
        return HTTPAPI(grabToken())
        .post("/attributes/devicetypeattribute/" + code, data);
    },
    deleteCommonAttribute: (attributeName) => {        
        return HTTPAPI(grabToken())
        .delete("/attributes/commonattribute?name=" + attributeName)
    },
    deleteNetworkAttribute: (code, attributeName) => {        
        return HTTPAPI(grabToken())
        .delete("/attributes/networkattribute/" + code + "/?name=" + attributeName)
    },
    deleteDeviceTypeAttribute: (code, attributeName) => {        
        return HTTPAPI(grabToken())
        .delete("/attributes/devicetypeattribute/" + code + "/?name=" + attributeName)
    },
    // getAll: () => {        
    //     return HTTPAPI(grabToken())
    //     .get("/network")
    // },
    // delete: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .delete("/network/" + code)
    // },
    // getOne: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/network/" + code)
    // },
    // update: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/network/" + code, data);
    // },
    // create: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/network/", data);
    // },
}

export default attributes;
