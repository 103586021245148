import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";
    return token;
}

const brand = {
    getList: (urlCriteria) => {        
        return HTTPAPI(grabToken())
        .get("/brand/list" + urlCriteria)
    },
    getAll: () => {        
        return HTTPAPI(grabToken())
        .get("/brand")
    },
    delete: (code) => {        
        return HTTPAPI(grabToken())
        .delete("/brand/" + code)
    },
    getOne: (code) => {        
        return HTTPAPI(grabToken())
        .get("/brand/" + code)
    },
    update: (code, data) => {
        return HTTPAPI(grabToken())
        .patch("/brand/" + code, data);
    },
    create: (data) => {
        return HTTPAPI(grabToken())
        .post("/brand/", data);
    },
    // signUp: (data) => {
    //     return HTTPAPI()
    //     .post("/users/register", data)
    // },
    // activateUser: (code) => {
    //     return HTTPAPI()
    //     .post("/users/activate", {code})
    // },
    // login: (data) => {
    //     return HTTPAPI()
    //     .post("/auth/login", data)
    // },    
    // logout: (data) => {        
    //     return HTTPAPI(grabToken())
    //     .post("/auth/logout", data)
    // },
    // forgotPass: (data) => {
    //     return HTTPAPI()
    //     .post("/users/forgotpass", data)
    // },
    // validateResetPassCode: (code) => {
    //     return HTTPAPI()
    //     .post("/users/validatepassreset", {code})
    // },
    // changePassword: (data) => {
    //     return HTTPAPI()
    //     .post("/users/changepass", data)
    // }
}

export default brand;
