import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";    
    return token;
}

const campaign = {
    uploadAsset: (data, campaignId, assetIndex, assetCode, onUploadProgress) => {
        return HTTPAPI(grabToken())
        .post(
            "/campaign/uploadasset?campaign=" + campaignId + "&assetIndex=" + assetIndex + "&assetCode=" + assetCode
            ,data
            ,{
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                onUploadProgress
            }
        );
    },    
    getInitData: () => {
        return HTTPAPI(grabToken())
        .get("/campaign/refdata")
    },
    getOne: (code) => {                
        return HTTPAPI(grabToken())
        .get("/campaign/" + code)
    },
    create: (data) => {
        return HTTPAPI(grabToken())
        .post("/campaign/", data);
    },
    update: (id, data) => {
        return HTTPAPI(grabToken())
        .patch("/campaign/" + id, data);
    },
    delete: (id) => {        
        return HTTPAPI(grabToken())
        .delete("/campaign/" + id)
    },
    getList: (urlCriteria) => {        
        return HTTPAPI(grabToken())
        .get("/campaign/list" + urlCriteria)
    },
    getOptionsRefList: () => {        
        return HTTPAPI(grabToken())
        .get("/campaign/optionsRefList")
    },    
    getCampaignProgressStatistics: (code) => {
        return HTTPAPI(grabToken())
        .get("/campaign/progressstats/" + code)
    },
    getCampaignActivityAdpoints: (code, urlCriteria) => {
        return HTTPAPI(grabToken())
        .get("/campaign/activitystatsadpoints/" + code + urlCriteria)
    },
    
    release: (id, data) => {
        return HTTPAPI(grabToken())
        .patch("/campaign/release/" + id, data);
    },
    complete: (id, data) => {
        return HTTPAPI(grabToken())
        .patch("/campaign/complete/" + id, data);
    },
    archive: (id, data) => {
        return HTTPAPI(grabToken())
        .patch("/campaign/archive/" + id, data);
    },
    duplicate: (id) => {
        return HTTPAPI(grabToken())
        .post("/campaign/duplicate/" + id);
    },
    deliverycalc: (data) => {
        return HTTPAPI(grabToken())
        .post("/campaign/deliverycalc", data);
    },
    availablecapacity: (data) => {
        return HTTPAPI(grabToken())
        .post("/campaign/availablecapacity", data);
    },
    analysisdata: (data) => {
        return HTTPAPI(grabToken())
        .post("/campaign/analysisdata", data);
    },    
    calcprogress: (data) => {
        return HTTPAPI(grabToken())
        .get("/campaign/calcprogress", data);
    },
    // invite: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/users/invite", data)
    // },
    // getUser: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/users/" + code)
    // },
    // invite: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/users/invite", data)
    // },
    // update: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/users/" + code, data);
    // },
    // disable: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/users/disable/" + code, data);
    // },
    // reset: (code) => {
    //     return HTTPAPI(grabToken())
    //     .get("/users/reset/" + code);
    // }
    

    // getInitData: () => {
    //     return HTTPAPI(grabToken())
    //     .get("/adpoint/initdata")
    // },

    // getInitEditData: (code) => {
    //     if(code) {
    //         return HTTPAPI(grabToken())
    //         .get("/adpoint/initeditdata/" + code);
    //     }
    //     return HTTPAPI(grabToken())
    //     .get("/adpoint/initeditdata");
    // },
    // update: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/adpoint/" + code, data);
    // },
    // create: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/adpoint/", data);
    // },







    // signUp: (data) => {
    //     return HTTPAPI()
    //     .post("/users/register", data)
    // },
    // activateUser: (code) => {
    //     return HTTPAPI()
    //     .post("/users/activate", {code})
    // },
    // login: (data) => {
    //     return HTTPAPI()
    //     .post("/auth/login", data)
    // },    
    // logout: (data) => {        
    //     return HTTPAPI(grabToken())
    //     .post("/auth/logout", data)
    // },
    // forgotPass: (data) => {
    //     return HTTPAPI()
    //     .post("/users/forgotpass", data)
    // },
    // validateResetPassCode: (code) => {
    //     return HTTPAPI()
    //     .post("/users/validatepassreset", {code})
    // },
    // changePassword: (data) => {
    //     return HTTPAPI()
    //     .post("/users/changepass", data)
    // }
}

export default campaign;
