import axios from "axios";

const apiUrl = window.location.origin + "/api";

const grabRefreshToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const refreshtoken = uInfo != null ? (uInfo.refreshtoken ? uInfo.refreshtoken : "") : "";
    return refreshtoken;
}

function cleanUpResponseForLogging(response) {
    return {
        status: response.status,
        statusText: response.statusText,
        url: response.config.url,
        method: response.config.method,
        data: response.data

    }
}

function cleanUpRequestForLogging(request) {
    return {        
        url: request.url,
        method: request.method,
        data: request.data
    }
}

export const HTTPAPI = function(bearerToken) { 
    let r = axios.create({
        baseURL: apiUrl,
        headers: (bearerToken) ? {
            Authorization: `Bearer ${bearerToken}`
        } : {}
    });

    // if (process.env.VUE_APP_ENVIRONMENT && process.env.VUE_APP_ENVIRONMENT == "DEV") {
    //     r.interceptors.request.use(request => {
    //         console.log('\nAXIOS REQUEST LOG START ------------ \n' + JSON.stringify(cleanUpRequestForLogging(request), null, 2) + '\nAXIOS REQUEST LOG END ------------ \n');          
    //         return request
    //     });
        
    //     r.interceptors.response.use(response => {
    //         console.log('\nAXIOS RESPONSE LOG START ------------ \n' + JSON.stringify(cleanUpResponseForLogging(response), null, 2) + '\nAXIOS RESPONSE LOG END ------------ \n');                
    //         return response
    //     });
    // }

    r.interceptors.response.use(response => {
        return response;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/login" && originalConfig.url !== "/auth/refreshtoken" && err.response) {            
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {  
                console.log("attempting to refresh token");                              
                originalConfig._retry = true;
                try {
                    const rt = grabRefreshToken();
                    const rs = await r.post("/auth/refreshtoken", {
                        refreshToken: rt
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${rt}`
                        }
                    }
                    ).catch((err) => {
                        localStorage.removeItem('userInfo');                        
                        window.location.replace("/auth/login");                        
                    });
                    //grab new access token and refresh token and store them by replacing the old ones   
                    if(rs.data) {
                        const { token } = rs.data;                    
                        const userInfoData = localStorage.getItem("userInfo") != null
                            ? JSON.parse(localStorage.getItem("userInfo"))
                            : null;
                        localStorage.setItem("userInfo", JSON.stringify({...userInfoData, token: token}));
                        // const userInfoDataDebug = localStorage.getItem("userInfo") != null
                        //     ? JSON.parse(localStorage.getItem("userInfo"))
                        //     : null;
                        // console.log("NEW userInfo: " + JSON.stringify(userInfoDataDebug, null, 2));                                        
                        originalConfig.headers.Authorization= `Bearer ${token}`;                    
                        return r(originalConfig);
                    }                                     
                } catch (_error) {
                    return Promise.reject(_error);
                }                
            }
        }
        else {
            console.log("logged out");
        }
        return Promise.reject(err);
    });

    return r;            
}