

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import './plugins';

import octaviaKit from '@/plugins/octavia.kit';
import VueApexCharts from 'vue-apexcharts';
// import VNumeric from 'vuetify-numeric/vuetify-numeric.umd.min';
// import { VueMaskDirective } from "v-mask";
import * as VueGoogleMaps from 'vue2-google-maps';
import x5GMaps from 'x5-gmaps';
import VueTour from 'vue-tour';

// mock
import './fake-db/index.js';
import i18n from './lang/lang';


require('vue-tour/dist/vue-tour.css');

Vue.component('apexchart', VueApexCharts);

Vue.use(octaviaKit);
// Vue.use(VNumeric);
// Vue.directive("mask", VueMaskDirective);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjDlYIxeUG3t1cY4VqXzOV5wOym1x1Br8',
    libraries: 'places',
  }
});

Vue.use(x5GMaps, { 
  key: 'AIzaSyAjDlYIxeUG3t1cY4VqXzOV5wOym1x1Br8', 
  libraries: ['places'], 
  language: 'el',
  region: 'GR'
});

Vue.use(VueTour);

Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

new Vue({
  store,  
  beforeCreate() { this.$store.commit('initialiseStore')},
  router,
  i18n,
  vuetify,  
  render: (h) => h(App),
}).$mount('#app');
