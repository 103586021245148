import auth  from './auth';
import users  from './users';
import brand from './brand';
import devicetype from './devicetype';
import network from './network';
import attributes from './attributes';
import adpoint from './adpoint';
import event from './event';
import campaign from './campaign';
import hud from './hud';
import {  srvApi, srvApiNoAuth, PATH, getAssetUrl }  from './endpoints';

const api = {
    auth,
    users,
    brand,
    devicetype,
    network,
    attributes,
    adpoint,
    event,
    campaign,
    hud,    
};

export {api, srvApi, srvApiNoAuth, PATH, getAssetUrl};