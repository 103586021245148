import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";
    return token;
}

const hud = {
    // getList: (urlCriteria) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/network/list" + urlCriteria)
    // },
    myhomepageinfo: () => {        
        return HTTPAPI(grabToken())
        .get("hud/myhomepageinfo")
    },
    myglobalinfo: () => {        
        return HTTPAPI(grabToken())
        .get("hud/myglobalinfo")
    },
    nearbycities: (data) => {        
        return HTTPAPI(grabToken())
        .post("hud/nearbycities/", data);
    },
    // delete: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .delete("/network/" + code)
    // },
    // getOne: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/network/" + code)
    // },
    // update: (code, data) => {
    //     return HTTPAPI(grabToken())
    //     .patch("/network/" + code, data);
    // },
    // create: (data) => {
    //     return HTTPAPI(grabToken())
    //     .post("/network/", data);
    // },
    // runSync: (code) => {        
    //     return HTTPAPI(grabToken())
    //     .get("/network/sync/" + code)
    // },
}

export default hud;
