import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";    
    return token;
}

const auth = {
    signUp: (data) => {
        return HTTPAPI()
        .post("/users/register", data)
    },
    activateUser: (code) => {
        return HTTPAPI()
        .post("/users/activate", {code})
    },
    validateActivationCode: (code) => {
        return HTTPAPI()
        .post("/users/validateactivationcode", {code})
    },
    requestSmsVerification: (code, mobile, lang) => {
        return HTTPAPI()
        .post("/users/validatemobile", {code, mobile, lang})
    },
    validateSmsCode: (code, mobile, shortcode) => {
        return HTTPAPI()
        .post("/users/validatesmscode", {code, mobile, shortcode})
    },
    completeRegistration: (data)=> {
        return HTTPAPI()
        .post("/users/completeregistration", data)
    },
    login: (data) => {
        return HTTPAPI()
        .post("/auth/login", data)
    },    
    logout: (data) => {        
        return HTTPAPI(grabToken())
        .post("/auth/logout", data)
    },
    forgotPass: (data) => {
        return HTTPAPI()
        .post("/users/forgotpass", data)
    },
    validateResetPassCode: (code) => {
        return HTTPAPI()
        .post("/users/validatepassreset", {code})
    },    
    changePassword: (data) => {
        return HTTPAPI()
        .post("/users/changepass", data)
    },
    validateInvitationCode: (code) => {
        return HTTPAPI()
        .post("/users/validateinvcode", {code})
    }
}

export default auth;
