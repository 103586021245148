import { HTTPAPI } from "../base";

const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";    
    return token;
}

const event = {    
    getList: (urlCriteria) => {        
        return HTTPAPI(grabToken())
        .get("/event" + urlCriteria)
    },

    getInitData: () => {
        return HTTPAPI(grabToken())
        .get("/adpoint/initdata")
    },
}

export default event;
